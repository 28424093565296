import { useEffect } from 'react';
import { LoginForm } from '../../containers/authentication/loginForm/index';
import { DEFAULT_LOGIN_TYPE } from '../../containers/authentication/defaultLoginLink';
import { getAppConfig } from '../../hooks/useAppConfig';
import { goToRoute } from '@arivaa-react/react-router5';
/**
 * @description Login
 * @type Page
 * @author
 */
const Login = ({ match }) => {
  useEffect(() => {
    if (!getAppConfig('dbConfigured')) {
      goToRoute('create-db');
    }
  }, []);
  return <LoginForm type={match?.params?.type || DEFAULT_LOGIN_TYPE} />;
};

Login.displayName = 'Login';
Login.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
Login.url = 'login/:type?';
export default Login;
