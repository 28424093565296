import { WebCrud } from '../../../containers/crud/webCrud';
import { Crud } from '../../../containers/crud/crud';
import { Table } from '../../../containers/crud/table';
import LongText from '@arivaa-react/components/longtext';
import { Button, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { url } from '../../../constants/api';
import { useAuth } from '../../../hooks/useAuth';
import { DownloadOutlined } from '@ant-design/icons';
import { formatDate } from '@arivaa-react/helpers/date-util';
export default function SystemLogs() {
  const { createLinkWithAuthorizationParam } = useAuth();
  return (
    <WebCrud api="system-log">
      <Crud.Header title="System Logs">
        <Crud.Header.Actions>
          <Crud.Header.Actions.Refresh />
          <a href={createLinkWithAuthorizationParam(url`system-log/download`)}>
            <Button type="primary">
              <Space>
                <DownloadOutlined /> Download Log File
              </Space>
            </Button>
          </a>
        </Crud.Header.Actions>
      </Crud.Header>
      <Crud.Table
        actions={[Table.RowActions.Delete]}
        columns={[
          {
            title: 'Level',
            dataIndex: 'level',
          },
          {
            title: 'Message',
            dataIndex: 'message',
            render: (message) => (
              <LongText text={message} limit={200} title="Full Log" />
            ),
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (createdAt) => {
              return formatDate(createdAt);
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'message',
                inputProps: {
                  placeholder: 'Search for logs',
                },
              },
              {
                type: 'custom',
                name: 'createdAt',
                Component: ({ value, ...props }) => {
                  return (
                    <DatePicker.RangePicker
                      {...props}
                      value={value?.map((item) => dayjs(item))}
                      onChange={(value) =>
                        props?.onChange(
                          value?.map((item) => item?.toISOString())
                        )
                      }
                    />
                  );
                },
                inputProps: {
                  placeholder: 'From Date',
                  showTime: true,
                },
                operator: 'between',
                initialValue: [new Date(), new Date()],
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
