import { useEffect } from 'react';
import './styles.scss';
import Link from '@arivaa-react/components/link';
import { AuthFormWrapper } from '../authFormWrapper';
import { goToLogin } from '../authentication/defaultLoginLink';
import { OtpInput } from './otpInput';
import { useVerificationData } from '../authentication/useVerificationData';
import { useApiWithSideEffects } from '../../hooks/api';
import { translate } from '../../localization';
import { ApiForm } from '../apiForm';
import { message } from 'antd';
import { url } from '../../constants/api';
const spinnerSelector = '.auth-form';
/**
 * @description Otp Verification Form
 * @type Container
 * @author Inderdeep
 */
export const OtpVerification = () => {
  const { setVerificationData, verificationData } = useVerificationData();
  const { callApi } = useApiWithSideEffects({
    errorMessage: translate('common.request.error'),
    successMessage: translate('verification.resend.success'),
    spinnerSelector,
  });
  useEffect(() => {
    if (!verificationData) {
      goToLogin();
    }
  }, []);

  const onSuccess = async (response) => {
    if (verificationData.onVerify instanceof Function) {
      verificationData.onVerify(response, verificationData.confirmationKey);
    }
  };

  /**
   * On Submit of  Form
   * @param event
   */
  const onResend = async (event) => {
    event.preventDefault();
    try {
      const { key, verificationCode } = await callApi(
        url(
          verificationData.resendApi + '/' + verificationData.confirmationKey
        ),
        {
          method: 'GET',
          ...verificationData.resendApiConfig,
        }
      );
      setVerificationData({
        ...verificationData,
        confirmationKey: key,
        verificationCode,
      });
    } catch (e) {
      message.error(translate('common.server.error.unexpected'));
    }
  };

  return verificationData ? (
    <AuthFormWrapper
      title={verificationData.title || 'Verification'}
      description={
        verificationData.description ||
        `Please enter the code sent to your ${verificationData.confirmationType}`
      }
    >
      <ApiForm
        elements={[
          {
            type: 'custom',
            name: verificationData.fieldName || 'verificationCode',
            label: 'Verification Code',
            required: true,
            Component: OtpInput,
            inputProps: {
              length: 4,
              className: 'otp-item',
            },
            formItemProps: {
              hasFeedback: false,
            },
            options: {
              initialValue: `${verificationData.verificationCode || ''}`,
            },
          },
        ]}
        onSuccess={onSuccess}
        onError={verificationData.onError}
        submitButtonProps={{
          children: 'Verify',
        }}
        errorMessage={translate('verification.fail')}
        extra={{
          ...verificationData.extra,
        }}
        apiConfig={verificationData.apiConfig}
        apiUrl={url(
          `${verificationData.api}/${verificationData.confirmationKey}`
        )}
      />
      <p className="option">
        Did't receive a code? <Link onClick={onResend}>Resend</Link>
      </p>
    </AuthFormWrapper>
  ) : null;
};
